import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  subscription

constructor() { }
  getRiderItemCount() {
    let count = localStorage.getItem('rider_item_count')
    if(count == '' || count == undefined) {
      return 10
    } else {
      return Number(count)
    }
  }
  saveRiderItemCount(count) {
    localStorage.setItem('rider_item_count', count)
  }

  getProductItemCount() {
    let count = localStorage.getItem('product_item_count')
    if(count == '' || count == undefined) {
      return 10
    } else {
      return Number(count)
    }
  }
  saveProductItemCount(count) {
    localStorage.setItem('product_item_count', count)
  }

  saveSubscription(_sub) {
    this.subscription = _sub
  }

  getSubscription() {
    return this.subscription
  }

  saveTableInfo(tableName, data) {
    localStorage.setItem(tableName, JSON.stringify(data))
  }

  getTableInfo(tableName) {
    let data = localStorage.getItem(tableName)
    if(data == undefined || data == '') {
      return {
        page : 1,
        perPage : 10
      }
    }
    return JSON.parse(data)
  }

}
