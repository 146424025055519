import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs'
import { environment } from '@env/environment';
import { Location } from '@angular/common';
import { EncryptService } from '@app/module/@shared/service/encrypt.service';
import { ToastrService } from 'ngx-toastr';

export interface TokenPayload {
  email : string,
  password : string
}

export interface UserPayload {
  id : number,
  role : number,
  approvable : number,
  email : string
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private token : string;
  private user : UserPayload;
  email = ""
  
  constructor(
    private http : HttpClient,
    private router : Router,
    private location : Location,
    private encryptService : EncryptService,
    private toastr : ToastrService,
  ) { 
    router.events.subscribe((val) => {
        // see also 
        console.log('val instanceof NavigationEnd',val) 
    });
  }

  public encryptData(data){
    var dateNow = new Date();
    var time = dateNow.toTimeString();
    var resTime = time.split(' ')[0];

    var result = data+'-'+resTime;
    
    return btoa(result);
    // btoa("password")
    // this.encryptService.encryptPage(data)
    // .subscribe(
    //   res => {
    //     // console.log(res)
    //     this.router.navigateByUrl(`authenticated/pfr/sign/manage/${res.result}`)
    //   },
    //   err => {
    //     console.log(err)
    //     this.toastr.success("Failed")
    //   }
    // )
  }

  public decryptData(data){
    var result = atob(data);
    console.log('result',result)
    return result.split('-');
  }

  // public encryptAes(data){
  //   return CryptoJS.AES.encrypt('encyrpt', data).toString();
  // }

  // public decryptAes(data){
  //   return CryptoJS.AES.decrypt('encrypt', data).toString(CryptoJS.enc.Utf8);
  // }

  public checkPerm(data){
    if(data === false){
      this.router.navigateByUrl(`authenticated/pfr/intro`);
      // this.location.back();
    }
  }
}