import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { ErrorComponent } from './module/authenticated/modules/error/error/error.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'guest',
    pathMatch: 'full',
    data : {
      preload : true
    }
  },
  // {
  //   path: 'maintenance',
  //   loadChildren: () => import("./module/maintenance/maintenance.module").then(m => m.MaintenanceModule),
  //   data : {
  //     preload : true
  //   }
  // },
  {
    path: 'guest',
    loadChildren: () => import("./module/guest/guest.module").then(m => m.GuestModule),
    data : {
      preload : true
    }
  },
  // {
  //   path: 'guest',
  //   redirectTo: 'maintenance',
  //   pathMatch: 'full',
  //   data : {
  //     preload : true
  //   }
  // },
  {
    path: 'authenticated',
    loadChildren: () => import("./module/authenticated/authenticated.module").then(m => m.AuthenticatedModule),
    canActivate: [ AuthGuardService ],
    data : {
      preload : true
    }
  },
  {
    path: 'callback',
    loadChildren: () => import('./module/authenticated/modules/singpass/singpass.module').then(m => m.SingpassModule),
  },
  {
    path: 'error',
    component: ErrorComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
