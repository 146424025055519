import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/authentication.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EncryptService {

  private pfrId : number;

  constructor(
    private authService : AuthenticationService,
    private http : HttpClient
  ) { }
  
  encryptPage(data : any) : Observable<any> {
    let url = `${environment.api_url}/api/encrypt/page`;
    return this.http.post(url, data, {
      headers : {
        'Content-Type' : 'application/json'
      }
    });
  }

  decryptPage(data : any) : Observable<any> {
    let url = `${environment.api_url}/api/decrypt/page`;
    return this.http.post(url, data, {
      headers : {
        'Content-Type' : 'application/json'
      }
    });
  }

}