import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(
    private router : Router,
    private auth : AuthenticationService
  ) {
    router.events.subscribe((val) => {
        // see also 
        // console.log('val instanceof NavigationEnd',val) 
    });
  }

  canActivate() {
    if(!this.auth.isLoggedIn()) {
      this.router.navigateByUrl('/guest');
      return false;
    }
    return true;
  }

  canActivateForVerify() {
    if(this.auth.email == '' || this.auth.email == undefined) {
      return false
    }
    return true;
  }

}
