import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

constructor(
  private auth : AuthenticationService
) { }
timeLeft: number = 60 * 60 * 3;
interval;

startTimer() {

  this.pauseTimer()
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.auth.logout();
        this.pauseTimer()
      }
    },1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
    this.timeLeft = 60 * 60 * 3;
  }
  refreshTimer() {

  }
}
