import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '@app/authentication.service';
import { catchError } from 'rxjs/operators';
import { TimerService } from '../service/timer.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthenticationService,
    public timer: TimerService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.auth.getToken()
    let user = this.auth.getUser()
    request = request.clone({
      setHeaders: {
        Authorization: `${this.auth.getToken()}`,
        UserId: `${this.auth.getUser().id}`
      }
    });
    this.timer.startTimer()
    return next.handle(request).pipe(
      catchError(error => {
        if(error instanceof HttpErrorResponse && error.status == 401) {
          this.auth.logout()
        }
        return throwError(error)
      })
    );
  }
}
